.search-container {
  position: relative;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-result-item:hover {
  background-color: #f5f5f5;
}

.search-result-item span {
  font-size: 16px;
  color: #333;
}

@media (min-width: 1280px) {
  .search-result-item span {
    font-size: 18px;
  }
}

@media (min-width: 1920px) {
  .search-result-item span {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .search-result-item span {
    font-size: 24px;
  }
}

