body {
  overflow: visible;
}

@supports (-webkit-touch-callout: none) {
  .height-table {
    overflow-y: scroll;
  }
}

.title-text {
  @apply text-sm md:text-base lg:text-lg xl:text-lg hd:text-lg 2xl:text-xl full-hd:text-4xl 3xl-hd:text-5xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-9xl;
}

.table-text {
  @apply w-[16%]
  text-center
  text-xs md:text-xs lg:text-xs xl:text-base 2xl:text-lg full-hd:text-xl 3xl-hd:text-xl qhd:text-2xl studio-laptop:text-3xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
  px-4 md:px-4 lg:px-4 xl:px-4 hd:px-4 2xl:px-4 full-hd:px-4 3xl-hd:px-4 qhd:px-4 studio-laptop:px-4 qhd-plus:px-4 4k:px-4 5k:px-4
  py-2 md:py-2 lg:py-2 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl-hd:py-6 qhd:py-6 studio-laptop:py-8 qhd-plus:py-12 4k:py-14 5k:py-14;
}

.table-title {
  @apply w-[17%]
    text-center
    text-xs md:text-xs lg:text-xs xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-5xl 4k:text-6xl 5k:text-7xl
    px-4 md:px-4 lg:px-4 xl:px-4 hd:px-4 2xl:px-4 full-hd:px-4 3xl-hd:px-4 qhd:px-4 studio-laptop:px-4 qhd-plus:px-4 4k:px-4 5k:px-4
    py-2 md:py-2 lg:py-2 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl-hd:py-4 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-12 5k:py-12;
}

.height-table {
  @apply h-[300px] xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-[300px] xl:h-[300px] hd:h-[400px] 2xl:h-[400px] full-hd:h-[500px] 3xl-hd:h-[600px] qhd:h-[760px] studio-laptop:h-[900px] qhd-plus:h-[1100px] 4k:h-[1200px] 5k:h-[1400px];
  overflow-y: auto; /* Adicionado para forçar scroll */
  -webkit-overflow-scrolling: touch; /* Para rolagem suave no Safari */
}

.margin-icon-text {
  @apply ml-1 xs:ml-1 sm:ml-1 md:ml-1 lg:ml-1 xl:ml-1 hd:ml-1 2xl:ml-2 full-hd:ml-2 3xl-hd:ml-2 qhd:ml-4 studio-laptop:ml-4 qhd-plus:ml-4 4k:ml-4 5k:ml-4;
}

.padding-container {
  @apply py-1 md:py-1 lg:py-4 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl-hd:py-2 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-8 5k:py-8
      px-4 md:px-4 lg:px-4 xl:px-6 hd:px-8 2xl:px-6 full-hd:px-12 3xl-hd:px-12 qhd:px-14 studio-laptop:px-20 qhd-plus:px-20 4k:px-24 5k:px-28;
}

.status-icon {
  @apply mr-1 lg:mr-2 xl:mr-3 hd:mr-4 2xl:mr-5 full-hd:mr-5 3xl-hd:mr-5 qhd:mr-6 studio-laptop:mr-6 qhd-plus:mr-6 4k:mr-8 5k:mr-8
        w-[20px] lg:w-[20px] xl:w-[20px] hd:w-[24px] 2xl:w-[28px] full-hd:w-[32px] 3xl-hd:w-[44px] qhd:w-[64px] studio-laptop:w-[72px] qhd-plus:w-[80px] 4k:w-[100px] 5k:w-[100px]
        h-[20px] lg:h-[20px] xl:h-[24px] hd:h-[32px] 2xl:h-[36px] full-hd:h-[44px] 3xl-hd:h-[48px] qhd:h-[64px] studio-laptop:h-[72px] qhd-plus:h-[80px] 4k:h-[100px] 5k:h-[100px];
}

.export-icon {
  @apply w-[20px] lg:w-[20px] xl:w-[20px] hd:w-[24px] 2xl:w-[28px] full-hd:w-[32px] 3xl-hd:w-[32px] qhd:w-[34px] studio-laptop:w-[40px] qhd-plus:w-[50px] 4k:w-[60px] 5k:w-[80px]
        h-[20px] lg:h-[20px] xl:h-[20px] hd:h-[24px] 2xl:h-[28px] full-hd:h-[32px] 3xl-hd:h-[32px] qhd:h-[34px] studio-laptop:h-[40px] qhd-plus:h-[50px] 4k:h-[60px] 5k:h-[80px];
}

.padding-date {
  @apply py-1 md:py-1 lg:py-4 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl-hd:py-2 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-8 5k:py-8
      px-4 md:px-4 lg:px-4 xl:px-6 hd:px-8 2xl:px-6 full-hd:px-12 3xl-hd:px-12 qhd:px-14 studio-laptop:px-20 qhd-plus:px-20 4k:px-24 5k:px-28;
}

.date-text {
  @apply text-sm md:text-base lg:text-lg xl:text-lg hd:text-lg 2xl:text-xl full-hd:text-4xl 3xl-hd:text-5xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-8xl leading-7;
}

.p-text {
  @apply text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-lg full-hd:text-2xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-5xl;
}

.datepicker {
  width: 100%;
}

.MuiPickersPopper-root {
  /* font-size: 2vw;  Aumenta o tamanho da fonte no calendário */
  font-size: clamp(12px, 2vw, 36px);
}

.MuiPickersDay-root {
  /* font-size: 2vw;  Aumenta o tamanho dos dias */
  font-size: clamp(12px, 2vw, 36px);
}

.MuiPickersCalendarHeader-switchViewButton {
  /*  font-size: 2vw;  Ajusta o botão de troca de visualização */
  font-size: clamp(12px, 2vw, 36px);
}

.MuiPaper-root {
  @apply transform scale-100;
}

@screen 5k {
  .MuiPaper-root {
    @apply transform scale-150;
  }
}

.MuiInputAdornment-root {
  /* font-size: 2vw; /* Escala o ícone */
  font-size: clamp(12px, 2vw, 36px);
}

.MuiSvgIcon-root {
  /* font-size: 2vw; /* Para ícones SVG */
  font-size: clamp(12px, 2vw, 36px);
}


.table-container {
  position: relative;
  max-height: calc(100vh - 300px);
}

.tbody-container {
  overflow-y: auto;
  max-height: calc(100vh - 350px); /* Ajuste este valor conforme necessário */
}

/* Removendo a classe height-table */
/*
.height-table {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
*/

table {
  @apply w-full border-collapse table-fixed;
}

th, td {
  @apply text-center align-middle;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  /* @apply sticky top-0 bg-white z-10; */
}

.tbody-container table {
  width: 100%;
}

.tbody-container td {
  background-color: white;
}

tbody tr:nth-child(even) {
  @apply bg-gray-50;
}

tbody tr:hover {
  @apply bg-gray-100;
}

.status-icon {
  @apply inline-block align-text-bottom mr-2;
}
