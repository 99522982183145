@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600&display=swap');

body {
  -webkit-tap-highlight-color: transparent;
}

.apple-card {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 14px;
  }
  
  .tab-active {
    color: #0071E3;
    position: relative;
  }
  
  .tab-active:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0071E3;
  }
  
  /* Adicione as cores personalizadas */
  :root {
    --primary-blue: #0071E3;
    --primary-green: #34C759;
    --primary-light: #F5F5F7;
    --primary-dark: #1D1D1F;
    --secondary-gray: #8E8E93;
  }
  
  .bg-primary-blue {
    background-color: var(--primary-blue);
  }
  
  .text-primary-blue {
    color: var(--primary-blue);
  }
  
  /* Adicione outras classes de cor conforme necessário */

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }