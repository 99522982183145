

.upgrade-background {
  background-image: url("/src/assets/UpgradeWalletBackgroundPNG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.outer-container {
  display: inline-block;
  padding: 1px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #2e2e2e, #ffffff, #aa7339);
}

.inner-container {
  border-radius: 12px;
}

.open-wallet-button {
  display: inline-block;
  padding: 1px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #2e2e2e, #ffffff, #aa7339);
}

.inner-open-wallet-button {
  border-radius: 12px;
}

