.title-text {
    @apply
        text-[8px] md:text-base lg:text-lg xl:text-lg hd:text-lg 2xl:text-xl full-hd:text-4xl 3xl-hd:text-5xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-8xl leading-7
}
  
.p-texte {
    @apply 
        text-[8px] lg:text-sm xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
}

.trash-icon {
    @apply
        md:w-[20px] lg:w-[20px] xl:w-[20px] hd:w-[24px] 2xl:w-[28px] full-hd:w-[32px] 3xl-hd:w-[44px] qhd:w-[64px] studio-laptop:w-[72px] qhd-plus:w-[80px] 4k:w-[100px] 5k:w-[100px]
        md:h-[20px] lg:h-[20px] xl:h-[24px] hd:h-[32px] 2xl:h-[36px] full-hd:h-[44px] 3xl-hd:h-[48px] qhd:h-[64px] studio-laptop:h-[72px] qhd-plus:h-[80px] 4k:h-[100px] 5k:h-[100px]
}

.height-table {
    @apply
    h-[300px] xs:h-[300px] sm:h-[300px] md:h-[300px] lg:h-[300px] xl:h-[300px] hd:h-[400px] 2xl:h-[400px] full-hd:h-[500px] 3xl-hd:h-[600px] qhd:h-[760px] studio-laptop:h-[900px] qhd-plus:h-[1100px] 4k:h-[1200px] 5k:h-[1400px] 

}