.fadeIn {
  animation: fadeIn 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*  xs: sm: md: lg: xl: hd: 2xl: full-hd: 3xl-hd: qhd: studio-laptop: qhd-plus: 4k: 5k: */

.padding-section {
  @apply
  py-[2%]
  px-16 xs:px-16 sm:px-16 md:px-16 lg:px-[68px] xl:px-24 hd:px-24 2xl:px-24 full-hd:px-24 3xl-hd:px-24 qhd:px-24 studio-laptop:px-24 qhd-plus:px-24 4k:px-24 5k:px-24
}

.border-radius-container {
  @apply
  rounded-3xl md:rounded-3xl lg:rounded-3xl xl:rounded-3xl hd:rounded-3xl 2xl:rounded-[36px] full-hd:rounded-[40px] 3xl-hd:rounded-[60px] qhd:rounded-[70px] studio-laptop:rounded-[80px] qhd-plus:rounded-[100px] 4k:rounded-[100px] 5k:rounded-[100px]
}

.last-transaction-container {
  @apply
    w-[90%] h-full
}

.title-container {
  @apply
    w-[90%] md:w-[90%] lg:w-[90%] xl:w-[90%] hd:w-[90%] 2xl:w-[90%] full-hd:w-[90%] 3xl-hd:w-[90%] qhd:w-[90%] studio-laptop:w-[90%] qhd-plus:w-[90%] 4k:w-[90%] 5k:w-[90%]
}

.balance-card-pix {
  @apply
    w-[90%] h-[90%]
    px-[4%] py-[2%]
}

.balance-card {
  @apply
    w-full h-[90%]
    px-[4%] py-[2%]
}

.crypto-card {
  @apply
    w-full h-full
}

.machine-card {
  @apply
    w-[90%] h-full
}
  
.exchange-card {
  @apply
    w-full h-full

}


.border-balance {
  @apply
    h-[50%] lg:h-[80%] xl:h-[80%] 4k:h-[80%] 5k:h-[80%] 
    w-[2px] md:w-[2px] lg:w-[2px] xl:w-[2px] hd:w-[2px] 2xl:w-[2px] full-hd:w-[2px] 3xl-hd:w-[2px] qhd:w-[2px] studio-laptop:w-[2px] qhd-plus:w-[2px] 4k:w-[2px] 5k:w-[8px]
    border
    border-[#E2EBFD] md:border-[#E2EBFD] lg:border-[#E2EBFD] xl:border-[#E2EBFD] hd:border-[#E2EBFD] 2xl:border-[#E2EBFD] full-hd:border-[#E2EBFD] 3xl-hd:border-[#E2EBFD] qhd:border-[#adb9d1] studio-laptop:border-[#adb9d1] qhd-plus:border-[#adb9d1] 4k:border-[#adb9d1] 5k:border-[#adb9d1]
}

.border-machine {
  @apply
    border md:border lg:border xl:border hd:border 2xl:border full-hd:border 3xl-hd:border qhd:border studio-laptop:border qhd-plus:border 4k:border 5k:border-4
    w-[60%]
    md:border-[#E2E8F0] lg:border-[#E2E8F0] xl:border-[#E2E8F0] hd:border-[#E2E8F0] 2xl:border-[#E2E8F0] full-hd:border-[#E2E8F0] 3xl-hd:border-[#E2E8F0] qhd:border-[#E2E8F0] studio-laptop:border-[#E2E8F0] qhd-plus:border-[#bdcadb] 4k:border-[#bdcadb] 5k:border-[#bdcadb] 
}

.gap-section {
  @apply
  space-y-[10px] md:space-y-[10px] lg:space-y-[10px] xl:space-y-[10px] hd:space-y-[10px] 2xl:space-y-[10px] full-hd:space-y-[10px] 3xl-hd:space-y-[10px] qhd:space-y-[10px] studio-laptop:space-y-[10px] qhd-plus:space-y-[10px] 4k:space-y-[10px] 5k:space-y-[10px]
}

.gap-sale {
  @apply
  gap-2 md:gap-2 lg:gap-2 xl:gap-8 hd:gap-8 2xl:gap-8 full-hd:gap-8 3xl-hd:gap-8 qhd:gap-8 studio-laptop:gap-8 qhd-plus:gap-8 4k:gap-8 5k:gap-8
}

.gap-machine {
  @apply 
    space-y-[20px] md:space-y-[20px] lg:space-y-[20px] xl:space-y-[20px] hd:space-y-[20px] 2xl:space-y-[20px] full-hd:space-y-[20px] 3xl-hd:space-y-[40px] qhd:space-y-[40px] studio-laptop:space-y-[60px] qhd-plus:space-y-[60px] 4k:space-y-[54px] 5k:space-y-[74px]
}

.title-text {
  @apply
  font-bold text-[8px] md:text-base lg:text-lg xl:text-lg hd:text-lg 2xl:text-xl full-hd:text-4xl 3xl-hd:text-4xl qhd:text-4xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-8xl leading-7
}

.crypto-price-text {
  @apply 
  text-[8px] md:text-sm lg:text-sm xl:text-sm hd:text-sm 2xl:text-lg full-hd:text-xl 3xl-hd:text-2xl qhd:text-2xl studio-laptop:text-3xl qhd-plus:text-3xl 4k:text-4xl 5k:text-5xl
}

.p-texte {
  @apply 
  text-[8px] lg:text-sm xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
}

.crypto-type-text {
  @apply 
  text-[8px] md:text-sm lg:text-base xl:text-base hd:text-sm 2xl:text-sm full-hd:text-xl 3xl-hd:text-3xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-5xl 4k:text-5xl 5k:text-6xl

}

.exchange-text {
  @apply
  text-xs md:text-xs lg:text-sm xl:text-sm hd:text-base 2xl:text-lg full-hd:text-xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-5xl 4k:text-6xl 5k:text-6xl
}

.conversion-text {
  @apply
  text-xs xs:text-sm sm:text-base md:text-base lg:text-xl xl:text-xl hd:text-xl 2xl:text-xl full-hd:text-3xl 3xl-hd:text-4xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-7xl 4k:text-7xl 5k:text-8xl
}

.machine-text {
  @apply
    font-semibold text-xs xs:text-xs sm:text-xs md:text-base lg:text-lg xl:text-lg hd:text-xl 2xl:text-xl full-hd:text-2xl 3xl-hd:text-3xl qhd:text-4xl studio-laptop:text-5xl qhd-plus:text-5xl 4k:text-6xl 5k:text-7xl
}

.gap-container-exchange-machine {
  @apply
    gap-4 xs:gap-4 sm:gap-6 md:gap-8 lg:gap-8 xl:gap-10 hd:gap-10 2xl:gap-10 full-hd:gap-12 3xl-hd:gap-12 qhd:gap-24 studio-laptop:gap-24 qhd-plus:gap-24 4k:gap-24 5k:gap-24
}

.eye-icon {
  @apply
  md:w-[20px] lg:w-[20px] xl:w-[20px] hd:w-[24px] 2xl:w-[28px] full-hd:w-[32px] 3xl-hd:w-[44px] qhd:w-[64px] studio-laptop:w-[72px] qhd-plus:w-[80px] 4k:w-[100px] 5k:w-[100px]
  md:h-[20px] lg:h-[20px] xl:h-[24px] hd:h-[32px] 2xl:h-[36px] full-hd:h-[44px] 3xl-hd:h-[48px] qhd:h-[64px] studio-laptop:h-[72px] qhd-plus:h-[80px] 4k:h-[100px] 5k:h-[100px]
}

.last-icon {
  @apply
  md:w-[20px] lg:w-[20px] xl:w-[20px] hd:w-[24px] 2xl:w-[28px] full-hd:w-[32px] 3xl-hd:w-[44px] qhd:w-[64px] studio-laptop:w-[72px] qhd-plus:w-[80px] 4k:w-[100px] 5k:w-[100px]
  md:h-[20px] lg:h-[20px] xl:h-[20px] hd:h-[24px] 2xl:h-[28px] full-hd:h-[32px] 3xl-hd:h-[44px] qhd:h-[64px] studio-laptop:h-[72px] qhd-plus:h-[80px] 4k:h-[100px] 5k:h-[100px]
}

.exchange-icon {
  @apply
  md:w-[16px] lg:w-[16px] xl:w-[16px] hd:w-[16px] 2xl:w-[20px] full-hd:w-[24px] 3xl-hd:w-[28px] qhd:w-[32px] studio-laptop:w-[40px] qhd-plus:w-[50px] 4k:w-[60px] 5k:w-[80px]
  md:h-[16px] lg:h-[16px] xl:h-[16px] hd:h-[16px] 2xl:h-[20px] full-hd:h-[24px] 3xl-hd:h-[28px] qhd:h-[32px] studio-laptop:h-[40px] qhd-plus:h-[50px] 4k:h-[60px] 5k:h-[80px]
}

.crypto-icon {
  @apply
  w-[30%] 
  h-[30%]
}
 
.crypto-icon-area {
  @apply
    grid-cols-[10%,15%,15%,8%,15%,15%,15%] 
}

.crypto-graph-icon {
  @apply
  w-[34%] flex items-end justify-end
}

.crypto-graph-line-icon {
  @apply
  w-[70%]
}

.crypto-button {
  @apply
  w-[90%]
  h-[50%]
  rounded-lg md:rounded-lg lg:rounded-lg xl:rounded-lg hd:rounded-lg 2xl:rounded-lg full-hd:rounded-[14px] 3xl-hd:rounded-[16px] qhd:rounded-[20px] studio-laptop:rounded-[20px] qhd-plus:rounded-[30px] 4k:rounded-[30px] 5k:rounded-[30px]
  text-[8px] xs:text-[8px] sm:text-[8px] md:text-[10px] lg:text-[10px] xl:text-[10px] hd:text-[10px] 2xl:text-[10px] full-hd:text-[10px] 3xl-hd:text-lg qhd:text-xl studio-laptop:text-2xl qhd-plus:text-2xl 4k:text-3xl 5k:text-3xl

}

.sale-icon {
  @apply
  w-[40px] md:w-[40px] lg:w-[50px] xl:w-[60px] hd:w-[70px] 2xl:w-[90px] full-hd:w-[102px] 3xl-hd:w-[112px] qhd:w-[144px] studio-laptop:w-[180px] qhd-plus:w-[198px] 4k:w-[204px] 5k:w-[220px]
  h-[40px] md:h-[40px] lg:h-[50px] xl:h-[60px] hd:h-[70px] 2xl:h-[90px] full-hd:h-[102px] 3xl-hd:h-[112px] qhd:h-[144px] studio-laptop:h-[180px] qhd-plus:h-[198px] 4k:h-[204px] 5k:h-[220px]
}

.total-balance {
  @apply
  text-base md:text-base lg:text-lg xl:text-2xl hd:text-2xl 2xl:text-2xl full-hd:text-3xl 3xl-hd:text-3xl qhd:text-5xl studio-laptop:text-5xl qhd-plus:text-5xl 4k:text-7xl 5k:text-8xl
}

.total-gap {
  @apply
  gap-5 md:gap-5 lg:gap-5 xl:gap-5 hd:gap-5 2xl:gap-6 full-hd:gap-8 3xl-hd:gap-8 qhd:gap-8 studio-laptop:gap-20 qhd-plus:gap-14 4k:gap-20 5k:gap-20
}

.transaction-item-gap {
  @apply
  gap-3 md:gap-3 lg:gap-3 xl:gap-3 hd:gap-3 2xl:gap-3 full-hd:gap-4 3xl-hd:gap-4 qhd:gap-4 studio-laptop:gap-8 qhd-plus:gap-12 4k:gap-16 5k:gap-20
}

.gap-exchange {
  @apply
  md:gap-2 lg:gap-2 xl:gap-2 hd:gap-2 2xl:gap-2 full-hd:gap-4 3xl-hd:gap-4 qhd:gap-4 studio-laptop:gap-6 qhd-plus:gap-6 4k:gap-8 5k:gap-8
}

.gap-crypto-items {
  @apply
  gap-1
}

/* .gap-crypto-wrapper {
  @apply
    md:gap-8 lg:gap-8 xl:gap-8 hd:gap-8 2xl:gap-8 full-hd:gap-8 3xl-hd:gap-8 qhd:gap-8 studio-laptop:gap-8 qhd-plus:gap-8 4k:gap-8 5k:gap-36
    md:py-1 lg:py-2 xl:py-2 hd:py-3 2xl:py-3 full-hd:py-3 3xl-hd:py-3 qhd:py-3 studio-laptop:py-3 qhd-plus:py-4 4k:py-96 5k:py-4
    md:px-12 lg:px-12 xl:px-12 hd:px-12 2xl:px-12 full-hd:px-12 3xl-hd:px-14 qhd:px-16 studio-laptop:px-20 qhd-plus:px-28 4k:px-96 5k:px-40
} */

.padding-container {
  @apply
    py-1 md:py-1 lg:py-4 xl:py-2 hd:py-6 2xl:py-4 full-hd:py-6 3xl-hd:py-6 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-8 5k:py-8
    px-4 md:px-4 lg:px-4 xl:px-6 hd:px-8 2xl:px-6 full-hd:px-12 3xl-hd:px-12 qhd:px-14 studio-laptop:px-20 qhd-plus:px-20 4k:px-24 5k:px-28
}

.wrapper-total-gap {
  @apply
  gap-5 md:gap-5 lg:gap-5 xl:gap-5 hd:gap-5 2xl:gap-5 full-hd:gap-10 3xl-hd:gap-10 qhd:gap-10 studio-laptop:gap-10 qhd-plus:gap-10 4k:gap-10 5k:gap-20
}

.machine-items-gap {
  @apply
  gap-4 xs:gap-4 sm:gap-4 md:gap-4 lg:gap-4 xl:gap-4 hd:gap-6 2xl:gap-6 full-hd:gap-12 3xl-hd:gap-12 qhd:gap-12 studio-laptop:gap-12 qhd-plus:gap-12 4k:gap-16 5k:gap-20
}

.pix-icon {
  @apply
  w-[14px] xs:w-[14px] sm:w-[16px] md:w-[16px] lg:w-[16px] xl:w-[24px] hd:w-[34px] 2xl:w-[40px] full-hd:w-[50px] 3xl-hd:w-[52px] qhd:w-[80px] studio-laptop:w-[80px] qhd-plus:w-[100px] 4k:w-[110px] 5k:w-[120px] 
}

.gap-header-pix {
  @apply
    gap-2 md:gap-2 lg:gap-2 xl:gap-4 hd:gap-4 2xl:gap-2 full-hd:gap-6 3xl-hd:gap-8 qhd:gap-8 studio-laptop:gap-10 qhd-plus:gap-10 4k:gap-12 5k:gap-12
}

.gap-header-pix-mobile {
  @apply
    gap-2 md:gap-2 lg:gap-2 xl:gap-4 hd:gap-4 2xl:gap-2 full-hd:gap-6 3xl-hd:gap-8 qhd:gap-8 studio-laptop:gap-10 qhd-plus:gap-10 4k:gap-12 5k:gap-12
}

.wrapper-icons-pix {
  @apply
    w-[30px] md:w-[36px] lg:w-[50px] xl:w-[50px] hd:w-[70px] 2xl:w-[82px] full-hd:w-[104px] 3xl-hd:w-[120px] qhd:w-[148px] studio-laptop:w-[160px] qhd-plus:w-[180px] 4k:w-[212px] 5k:w-[300px]
    h-[30px] md:h-[36px] lg:h-[50px] xl:h-[50px] hd:h-[70px] 2xl:h-[82px] full-hd:h-[104px] 3xl-hd:h-[120px] qhd:h-[148px] studio-laptop:h-[160px] qhd-plus:h-[180px] 4k:h-[212px] 5k:h-[300px]
}

.wrapper-icons-last {
  @apply
    w-[16px] md:w-[20px] lg:w-[20px] xl:w-[16px] hd:w-[1.5rem] 2xl:w-[1rem] full-hd:w-[2rem] 3xl-hd:w-[3rem] qhd:w-[4rem] studio-laptop:w-[4.5rem] qhd-plus:w-[5rem] 4k:w-[5.5rem] 5k:w-[6.5rem]
}


.icons-pix {
  @apply
    w-[16px] md:w-[20px] lg:w-[24px] xl:w-[30px] hd:w-[36px] 2xl:w-[40px] full-hd:w-[60px] 3xl-hd:w-[64px] qhd:w-[84px] studio-laptop:w-[96px] qhd-plus:w-[104px] 4k:w-[124px] 5k:w-[180px]
}


.icon-machine {
  @apply
    w-[8%] md:w-[8%] lg:w-[8%] xl:w-[8%] hd:w-[8%] 2xl:w-[8%] full-hd:w-[10%] 3xl-hd:w-[12%] qhd:w-[10%] studio-laptop:w-[10%] qhd-plus:w-[10%] 4k:w-[8%] 5k:w-[8%]
}

.icon-last {
  @apply
    w-[8%] md:w-[8%] lg:w-[8%] xl:w-[8%] hd:w-[12%] 2xl:w-[20%] full-hd:w-[10%] 3xl-hd:w-[12%] qhd:w-[10%] studio-laptop:w-[10%] qhd-plus:w-[10%] 4k:w-[10%] 5k:w-[10%]
    h-[8%] md:h-[8%] lg:h-[8%] xl:h-[8%] hd:h-[6%] 2xl:h-[8%] full-hd:h-[10%] 3xl-hd:h-[12%] qhd:h-[10%] studio-laptop:h-[10%] qhd-plus:h-[10%] 4k:h-[10%] 5k:h-[10%]
}

.label-icon-pix {
  @apply
    text-[8px] md:text-[10px] lg:text-[10px] xl:text-xs hd:text-xs 2xl:text-sm full-hd:text-xl 3xl-hd:text-xl qhd:text-2xl studio-laptop:text-2xl qhd-plus:text-2xl 4k:text-3xl 5k:text-5xl
}

.transaction-debit-text {
  @apply
    text-[8px] md:text-[10px] lg:text-[10px] xl:text-xs hd:text-xs 2xl:text-sm full-hd:text-xl 3xl-hd:text-xl qhd:text-2xl studio-laptop:text-3xl qhd-plus:text-2xl 4k:text-4xl 5k:text-5xl
}

.gap-icons-pix {
  @apply
    gap-3 md:gap-3 lg:gap-3 xl:gap-3 hd:gap-3 2xl:gap-3 full-hd:gap-4 3xl-hd:gap-2 qhd:gap-8 studio-laptop:gap-8 qhd-plus:gap-8 4k:gap-10 5k:gap-10
}

.gap-button-icons-pix {
  @apply
    gap-3 md:gap-3 lg:gap-3 xl:gap-3 hd:gap-3 2xl:gap-3 full-hd:gap-4 3xl-hd:gap-4 qhd:gap-8 studio-laptop:gap-12 qhd-plus:gap-14 4k:gap-16 5k:gap-20
}


.transaction-date-text {
  @apply
  text-[8px] md:text-[8px] lg:text-xs xl:text-sm hd:text-sm 2xl:text-sm full-hd:text-lg 3xl-hd:text-xl qhd:text-2xl studio-laptop:text-3xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
}

.transaction-text {
  @apply
  text-[8px] md:text-[8px] lg:text-[8px] xl:text-[10px] hd:text-[10px] 2xl:text-sm full-hd:text-lg 3xl-hd:text-xl qhd:text-2xl studio-laptop:text-3xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
}

.transaction-width-date {
  @apply
    w-[50px] xs:w-[50px] sm:w-[70px] md:w-[70px] lg:w-[90px] xl:w-[140px] hd:w-[180px] 2xl:w-[140px] full-hd:w-[140px] 3xl-hd:w-[140px] qhd:w-[140px] studio-laptop:w-[140px] qhd-plus:w-[140px] 4k:w-[140px] 5k:w-[140px]
}

.height-order {
  @apply
  h-[30%]
}

.transaction-width-type-date {
  @apply
  gap-1  w-[50px] xs:w-[50px] sm:w-[70px] md:w-[70px] lg:w-[90px] xl:w-[140px] hd:w-[180px] 2xl:w-[140px] full-hd:w-[140px] 3xl-hd:w-[140px] qhd:w-[140px] studio-laptop:w-[140px] qhd-plus:w-[140px] 4k:w-[140px] 5k:w-[140px]
}