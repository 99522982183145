.custom-table {
    border-collapse: separate;
    border-spacing: 0 25px;
    text-align: left;
    width: 500px;
    background: #F5F5F5;
  }
  
  .custom-table tbody tr {
    background: #fff;
  }
  
  .custom-table tr td {
    padding: 10px;
  }

  .custom-table thead{
    background: #fff;
  }
  
