

.background {
  background-image: url("/src/assets/login-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.outer-container {
  display: inline-block;
  padding: 1px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #2e2e2e, #ffffff, #FF9500);
}

.inner-container {
  border-radius: 12px;
}

.open-wallet-button {
  display: inline-block;
  padding: 1px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #2e2e2e, #ffffff, #aa7339);
}

.inner-open-wallet-button {
  border-radius: 12px;
}

.border-radius-container {
  @apply
  rounded-3xl md:rounded-3xl lg:rounded-3xl xl:rounded-3xl hd:rounded-3xl 2xl:rounded-[36px] full-hd:rounded-[40px] 3xl-hd:rounded-[60px] qhd:rounded-[70px] studio-laptop:rounded-[80px] qhd-plus:rounded-[100px] 4k:rounded-[100px] 5k:rounded-[100px]
}

.title-text {
  @apply
      text-3xl full-hd:text-4xl 3xl-hd:text-5xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-8xl leading-7
}

.p-texte {
  @apply 
  text-[8px] lg:text-sm xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl-hd:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-6xl
}

.crypto-icon {
  @apply
  w-[28%] 
  h-[28%]
}

.sigin-button {
  @apply
  w-full
  h-[18%]
  rounded-lg md:rounded-lg lg:rounded-lg xl:rounded-lg hd:rounded-lg 2xl:rounded-lg full-hd:rounded-[14px] 3xl-hd:rounded-[16px] qhd:rounded-[20px] studio-laptop:rounded-[20px] qhd-plus:rounded-[30px] 4k:rounded-[30px] 5k:rounded-[30px]
  text-[8px] xs:text-[8px] sm:text-[8px] md:text-[10px] lg:text-[10px] xl:text-[10px] hd:text-[10px] 2xl:text-[10px] full-hd:text-[10px] 3xl-hd:text-lg qhd:text-xl studio-laptop:text-2xl qhd-plus:text-2xl 4k:text-3xl 5k:text-3xl

}

.icons-pix {
  @apply
    w-[16px] md:w-[20px] lg:w-[24px] xl:w-[30px] hd:w-[36px] 2xl:w-[40px] full-hd:w-[60px] 3xl-hd:w-[64px] qhd:w-[84px] studio-laptop:w-[96px] qhd-plus:w-[104px] 4k:w-[124px] 5k:w-[180px]
}

.icon-last {
  @apply
    w-[8%] md:w-[8%] lg:w-[8%] xl:w-[8%] hd:w-[12%] 2xl:w-[20%] full-hd:w-[10%] 3xl-hd:w-[12%] qhd:w-[10%] studio-laptop:w-[10%] qhd-plus:w-[10%] 4k:w-[10%] 5k:w-[10%]
    h-[8%] md:h-[8%] lg:h-[8%] xl:h-[8%] hd:h-[6%] 2xl:h-[8%] full-hd:h-[10%] 3xl-hd:h-[12%] qhd:h-[10%] studio-laptop:h-[10%] qhd-plus:h-[10%] 4k:h-[10%] 5k:h-[10%]
}

