.title-text {
  @apply
  text-sm md:text-base lg:text-lg xl:text-lg hd:text-lg 2xl:text-xl full-hd:text-4xl 3xl:text-5xl qhd:text-5xl studio-laptop:text-6xl qhd-plus:text-6xl 4k:text-8xl 5k:text-9xl 
}

.table-text {
  @apply 
  w-[33%]
  text-xs md:text-xs lg:text-xs xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-7xl
  px-4 md:px-4 lg:px-4 xl:px-4 hd:px-4 2xl:px-4 full-hd:px-4 3xl:px-4 qhd:px-4 studio-laptop:px-4 qhd-plus:px-4 4k:px-4 5k:px-4
  py-2 md:py-2 lg:py-2 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl:py-6 qhd:py-6 studio-laptop:py-8 qhd-plus:py-12 4k:py-14 5k:py-14
}

.table-title {
    @apply
    w-[33%]
    text-xs md:text-xs lg:text-xs xl:text-lg 2xl:text-lg full-hd:text-2xl 3xl:text-2xl qhd:text-3xl studio-laptop:text-4xl qhd-plus:text-4xl 4k:text-5xl 5k:text-7xl
    px-4 md:px-4 lg:px-4 xl:px-4 hd:px-4 2xl:px-4 full-hd:px-4 3xl:px-4 qhd:px-4 studio-laptop:px-4 qhd-plus:px-4 4k:px-4 5k:px-4
    py-2 md:py-2 lg:py-2 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl:py-4 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-12 5k:py-12
}

.height-table {
    @apply
    h-[400px] xs:h-[400px] sm:h-[400px] md:h-[400px] lg:h-[400px] xl:h-[400px] hd:h-[500px] 2xl:h-[500px] full-hd:h-[700px] 3xl:h-[800px] qhd:h-[960px] studio-laptop:h-[1120px] qhd-plus:h-[1400px] 4k:h-[1600px] 5k:h-[1800px] 

}

.padding-container {
    @apply
      py-1 md:py-1 lg:py-4 xl:py-2 hd:py-2 2xl:py-2 full-hd:py-2 3xl:py-2 qhd:py-6 studio-laptop:py-8 qhd-plus:py-8 4k:py-8 5k:py-8
      px-4 md:px-4 lg:px-4 xl:px-6 hd:px-8 2xl:px-6 full-hd:px-12 3xl:px-12 qhd:px-14 studio-laptop:px-20 qhd-plus:px-20 4k:px-24 5k:px-28
  }